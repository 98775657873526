import React from "react";

/*** COMPONENTS ***/
import Main from "../../components/main";
import SEO from "../../components/seo";
import "../../styles/illustration.css";
import Image from "../../gatsby_images/image-burst";

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes";

const burstPage = () => (
  <Main>
    <SEO title="Burst" />
    <div class="container-illustration">
      <div class="illustration-grid-item-11">
        <Image />
      </div>

      <div class="illustration-grid-item-12">
        <p class="illustration-bulletpoint-text">Program</p>
        <p class="illustration-bulletpoint-text"> &#9642; Krita</p>
      </div>
      <div class="illustration-grid-item-13">
        <p class="illustration-bulletpoint-text">Status</p>
        <p class="illustration-bulletpoint-text">
          {" "}
          &#8226; Completed 2020-03-24
        </p>
      </div>

      <div class="illustration-grid-item-14">
        <a
          class="illustration-text-back-to-projects"
          href={ROUTES.ILLUSTRATIONS}
        >
          &#8592; Back to illustrations
        </a>
      </div>
    </div>
  </Main>
);

export default burstPage;
